<template>
  <div id="title" class="title-component">
    <img class="bvb-logo" src="@/assets/bvb_logo.jpg" />
    <div
      class="title"
      :class="[
        this.$store.state.mobileView ? 'mobile' : 'desktop',
        this.$store.state.mode !== 'inperson' ? 'phone' : 'outside',
      ]"
    >
      {{ title }}
    </div>
    <button
      id="start"
      class="title-button"
      v-on:click="$store.commit('navigate', { route: nextLocation, data: '' })"
    >
      <!-- :class="this.$store.state.mobileView ? 'mobile' : 'desktop'" -->
      {{ buttonText }}
    </button>
    <img class="badger-mascot" src="@/assets/characters/mascot.png" />
    <div class="info-container">
      <!-- <img class="bvb-logo" src="@/assets/bvb_logo.jpg" /> -->
      <p class="copyright">Copyright 2021 The Sports Museum</p>
    </div>
  </div>
</template>

<script>
// import Focusable from "../focusManager";
export default {
  name: "Title",
  data() {
    return {
      title: "",
      buttonText: "",
    };
  },
  mounted() {
    this.$store.commit("getFocusableItems");
    window.dispatchEvent(new Event("resize"));
    this.title = this.$store.state.componentData.title
      ? this.$store.state.componentData.title
      : "No Title Provided";
    this.buttonText = this.$store.state.componentData.buttonText
      ? this.$store.state.componentData.buttonText
      : "Start";
    this.nextLocation = this.$store.state.componentData.nextRoute
      ? this.$store.state.componentData.nextRoute
      : "RoadMap";
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.title-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/backgrounds/track.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.title {
  font-size: clamp(12px, 3vw, 6vh);
  font-weight: bold;
  margin: 30px;
  color: white;
  background-color: var(--blue-gray);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 100px;
  padding-left: 100px;
  border-radius: 60px;
}

.title.mobile {
  font-size: clamp(12px, 6vw, 3vh);
}

.title-button {
  background-color: var(--yellow);
  font-size: clamp(12px, 4vw, 5vh) !important;
  font-family: inherit;
  border-radius: 50px;
  padding: 5px 45px !important;
  border-style: solid;
  border-color: white;
  border-width: 4px;
  box-shadow: 0px 5px 0px var(--blue);
  position: relative;
  z-index: 0;
  font-size: clamp(12px, 2vw, 3vh);
  font-weight: bold;
  color: var(--text-blue);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  min-height: 60px !important;
}

.badger-mascot {
  height: 50%;
  margin: 0 40px;
  margin-top: -2%;
  object-fit: contain;
  justify-self: flex-start;
  align-self: flex-start;
}

.info-container {
  position: absolute;
  right: 20px;
  bottom: 0px;
  text-align: right;
}

.info-container p {
  color: white;
}

.bvb-logo {
  width: 25%;
  margin-top: 2%;
}
</style>
